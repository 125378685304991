<template>
  <div>
    <form @submit.prevent="manageAnualReport">
      <!-- :loadData="loadData" -->
      <step1_detail
        :loadData="loadData"
        :view="view"
        :disbaledInput="disbaledInput"
        :data_form="data_form"
        :current_step="1"
        v-if="step_query == 1"
        :handleFilesChange="handleFilesChange"
        ref="step_1"
      />
      <step2_detail
        :loadData="loadData"
        :view="view"
        :disbaledInput="disbaledInput"
        :data_form="data_form"
        :current_step="2"
        v-if="step_query == 2"
        ref="step_2"
      />
      <buttons_steps
        :step_query="step_query"
        :canceAction="canceAction"
        :nextStep="nextStep"
      />
    </form>
  </div>
</template>

<script>
import step1_detail from "@/feature/annual_report_ev/presentation/views/steps/step1_detail";
import step2_detail from "@/feature/annual_report_ev/presentation/views/steps/step2_detail";
import buttons_steps from "@/feature/annual_report_ev/presentation/partials/buttons_steps";
import AnnualReportServices from "@/feature/annual_report_ev/data/services/annual_report_services";
import { dynamicsInput } from "../../../../core/utils/dynamicsInputs";

export default {
  components: {
    step1_detail,
    step2_detail,
    buttons_steps,
  },
  data() {
    return {
      paramId: null,
      current_step: 1,
      step_query: 1,
      disbaledInput: null,
      errors: {},
      view: "detail",
      data_form: {
        attachments: [],
        fecha_reporte:
          JSON.parse(sessionStorage.getItem("info_informe")).responsable || "",
        entidad_vigilada: "",
        responsable:
          JSON.parse(sessionStorage.getItem("info_informe")).responsable || "",
        tiempo: "",
        estado: "",
        pronunciamiento_obligatorio_entidad: "",
        descripcion_pronunciamiento_obligatorio_entidad: "",
        periodo_cumplimientos: "",
        periodo_cumplimientos_razones: "",
        periodo_razones: "",
        descripcion_periodo_razones: "",
        pronunciamiento_general: "",
        descripcion_pronunciamiento_general: "",
        requerimientos_humanos_tecnicos: "",
        descripcion_requerimientos_humanos_tecnicos: "",
        fecha_requerimientos_humanos_tecnicos: "",
        suministro_solicitado: "",
        fecha_suministro_solicitado: "",
        descripcion_suministro_solicitado: "",
      },
    };
  },
  created() {
    this.paramId = this.$route.params.id;
  },
  methods: {
    async loadData() {
      addEventListener("popstate", () => {
        this.step_query = this.$router.currentRoute.query.step;
      });

      this.paramId = this.$route.params.id;
      const { data } = await AnnualReportServices.getReport(this.paramId);

      if (data === null) {
        this.data_form = this.data_form;
      } else {
        this.disbaledInput = true;
        this.data_form = data;
      }

      return this.data_form;
    },
    handleFilesChange(selectedFiles) {
      return;
      // console.log(selectedFiles);
    },
    canceAction() {
      this.$router.push(`/dashboard/register-annual-report/`);
    },
    manageAnualReport() {
      this.$router.push(`/dashboard/register-annual-report/`);
    },
    validate() {},
    nextStep(step) {       
      this.current_step = step;
      this.step_query = step;
      this.$router.push(
        `/dashboard/register-annual-report/detail/${this.paramId}?step=${step}`
      );
    },
  },
};
</script>